import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import aws4 from 'aws4';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '../common/icon/Loader';
import EditItemPage from './EditItemPage';
import CommonPage from './CommonPage';
import Text from '../common/text/Text';

const ActionButton = styled.div`
  font-size: 0.9em;
  padding: 2px;
  cursor: ${props => props.display ? null : 'pointer'};
  border: none;
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  opacity: ${props => props.display ? 0 : 1}
`;

const SearchResultTableWrapper = styled.div`
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
`;

const SearchResultTableContainer = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
`;

const SearchResultTableBodyContainer = styled.tbody`
`;

const SearchResultRowContainer = styled.tr`
  color: #000;
  background-color: ${props => props.cellColor};
  font-weight: ${props => props.weight};
  position: ${props => props.sticky ? 'sticky' : null};
`;

const SearchResultItemContainer = styled.td`
  color: #000;
  font-family: "Tahoma";
  font-size: 0.8em;
  border: 1px solid #D0D0D0;
  padding: ${props => props.noPadding ? null : '8px'};
`;

const SearchResultItemWrapper = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const SearchResultItem = ({ item }) => (
  <SearchResultItemContainer>
    <SearchResultItemWrapper>
      { item }
    </SearchResultItemWrapper>
  </SearchResultItemContainer>
);

class SearchResultRow extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.child = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.child.current.open();
  }

  render() {
    const { onRequestDelete, row, headers, data, username } = this.props;
    return (
      <>
        <EditItemPage ref={this.child} id={row[row.length - 1]} data={data} headers={headers} />
        <SearchResultRowContainer cellColor={this.props.cellColor} weight={this.props.weight}>
          {username === 'taecho' || username === 'hyechoi' ? (
            <>
              <SearchResultItemContainer noPadding>
                <ActionButton onClick={() => onRequestDelete(row[row.length - 1])} display={this.props.noButton} backgroundColor="#F9C7C7"> Delete </ActionButton>
              </SearchResultItemContainer>
              <SearchResultItemContainer noPadding>
                <ActionButton onClick={() => this.toggle()} display={this.props.noButton} backgroundColor="#DDDDDD"> Edit </ActionButton>
              </SearchResultItemContainer>
            </>
            ) : <></> }
          { row.map(i => <SearchResultItem item = {i} />) }
        </SearchResultRowContainer>
      </>
    );
  }
}

class FullScanPageContent extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      numToShow: 30,
      headers: null,
      username: null,
      userInfo: null,
      loginCheck: true
    }
    this.onRequestDelete = this.onRequestDelete.bind(this);
  }

  componentDidMount = () => {
    Auth.currentUserInfo().then(res => {
      this.setState({
        username: res.username,
        loginCheck: false,
      })});
    this.makeRequest();
    this.getHeaders();
  }

  makeRequest = async () => {
    const opts = {
      method: 'GET',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: 'beta/data/all',
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: 'https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all'
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    const response = await axios.get('https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all', {
        headers: request.headers
      });

    this.setState({
      data: response.data.reverse(),
    });
  }

  getHeaders = async () => {
    const opts = {
      method: 'GET',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: 'beta/data/all?q=headers',
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: 'https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all?q=headers'
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    const response = await axios.get('https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all?q=headers', {
        headers: request.headers
      });

    this.setState({
      headers: response.data,
    });
  }

  loadItems() {
    this.setState(prevState => ({
      numToShow: prevState.numToShow + 50
    }));
  }

  onRequestDelete = async (id) => {
    let confirmation = window.confirm('Are you sure you want to delete this item?');
    if (confirmation) {
      const opts = {
        method: 'POST',
        service: 'execute-api',
        region: 'ap-northeast-2',
        path: `beta/data/delete`,
        host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
        url: `https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/delete`,
        headers: {
          'content-type' : 'application/json'
        },
        body: JSON.stringify(id)
      };

      const currentSession = await Auth.currentCredentials();
      const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
      const request = aws4.sign(opts, {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      });

      const response = await axios.post(`https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/delete`,
          JSON.stringify(id),
          {
            headers: request.headers,
          });
    }
  }

  render() {
    const { data, numToShow, headers, username } = this.state;
    return (
      data && headers ?
      <SearchResultTableWrapper>
        <SearchResultTableContainer>
          <SearchResultTableBodyContainer>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadItems.bind(this)}
              hasMore={numToShow < data.length}
              loader={<Loader width="30px" offCenter />}
            >
              <SearchResultRow username={username} headers={headers} noButton row = {Object.keys(data[0])} cellColor="#F5F5F5" weight="bold"/>
              {data.slice(0, numToShow).map(r => <SearchResultRow username={username} headers={headers} onRequestDelete={this.onRequestDelete} data={r} row = {Object.values(r)} data = {r}/>) }
            </InfiniteScroll>
          </SearchResultTableBodyContainer>
        </SearchResultTableContainer>
      </SearchResultTableWrapper> : <Loader width="50px" />
    );
  }
}

const FullScanPage = () => (
  <CommonPage
    maxWidth="1300px"
    title="PIMS Database Recent Entries: 한국디지브이(주)"
    highlight={<Text size="0.85em" content="Recently added items, sorted by last edited date." />}
    subhighlight={<Text size="0.85em" content="Add and delete access are exclusive to administrators." />}
    pageContent={<FullScanPageContent />} 
  />
);

export default withRouter(FullScanPage);
