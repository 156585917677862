import React from 'react';
import styled from 'styled-components';

import LoadingGif from '../../../assets/img/loader.gif';

const LoadingContainer = styled.img`
  width: ${props => props.width};
  object-fit: cover;
  margin-top: 20px;
  position: relative;
  left: ${props => !props.offCenter ? '50%' : null};
  transform: ${props => !props.offCenter ? 'translateX(-50%)' : null};
`;

const Loader = ({ width, offCenter }) => (
  <LoadingContainer src={LoadingGif} width={width} offCenter={offCenter}/>
)

export default Loader;
