import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Amplify, { Auth } from 'aws-amplify';
import aws4 from 'aws4';

import EditBar from './EditBar'
import Loader from '../common/icon/Loader';

const InputFormContainer = styled.form`
  padding: 20px;
  padding-top: 10px;
  text-align: center;
`;

const FlexBox = styled.div`
  display: flex;
`;

const InputBarContainer = styled.div`
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const InputColumnContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SubmitButton = styled.input`
  background-color: #DDDDDD;
  cursor: pointer;
  padding: 7px 20px;
  border: none;
  border-radius: 5px;
  font-size: 0.9em;
  margin-top: 20px;
  flex: 1;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const LoaderContainer = styled.div`
  flex: 1;
  text-align: center;
`;

class EditForm extends React.Component {
  constructor(props) {
    super();
    this.props = props;

    let prefilledDataset = props.data;

    this.state = {
      dataset: prefilledDataset,
      submitted: false,
    }
    this.getInput = this.getInput.bind(this);
  }

  makeEditRequest = async () => {
    const { dataset } = this.state;
    const { headers } = this.props;

    let empty = true;
    for (let i = 0; i < headers.length; i+=1) {
      if (dataset[headers[i]] !== '') {
        empty = false;
        break;
      }
    }

    if (empty) {
      alert('Entry cannot be empty - please fill out at least one item.');
      return;
    }

    this.setState({
      submitted: true,
    })

    const opts = {
      method: 'POST',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: `beta/data/edit`,
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: `https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/edit`,
      headers: {
        'content-type' : 'application/json'
      },
      body: JSON.stringify(dataset)
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    const response = await axios.post(`https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/edit`,
        JSON.stringify(dataset),
        {
          headers: request.headers,
        });

    if (response.status === 200) {
      this.setState({
        submitted: false,
      })
    }
  }

  makeAddRequest = async () => {
    const { dataset } = this.state;
    const { headers } = this.props;

    let empty = true;
    for (let i = 0; i < headers.length; i+=1) {
      if (dataset[headers[i]] !== '') {
        empty = false;
        break;
      }
    }

    if (empty) {
      alert('Entry cannot be empty - please fill out at least one item.');
      return;
    }

    this.setState({
      submitted: true,
    })

    const opts = {
      method: 'POST',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: `beta/data/add`,
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: `https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/add`,
      headers: {
        'content-type' : 'application/json'
      },
      body: JSON.stringify(dataset)
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    const response = await axios.post(`https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/add`,
        JSON.stringify(dataset),
        {
          headers: request.headers,
        });

    if (response.status === 200) {
      this.setState({
        submitted: false,
      })
      //window.location = './add';
    }
  }

  getInput(input, id) {
    const { dataset } = this.state;
    dataset[id] = input;
    this.setState({ dataset });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.makeRequest();
  }

  render() {
    const { headers } = this.props;
    const { dataset, submitted } = this.state;

    return (
      <InputFormContainer onSubmit={this.onSubmit}>
        <FlexBox>
          <InputColumnContainer>
            {headers.slice(0, headers.length/3).map(header => (
              <InputBarContainer>
                <EditBar getInput={this.getInput} existingValue={dataset[header]} headers={headers} id={header}/>
              </InputBarContainer>
            ))}
          </InputColumnContainer>
          <InputColumnContainer>
            {headers.slice(headers.length/3, 2*headers.length/3).map(header => (
              <InputBarContainer>
                <EditBar getInput={this.getInput} existingValue={dataset[header]} headers={headers} id={header}/>
              </InputBarContainer>
            ))}
          </InputColumnContainer>
          <InputColumnContainer>
            {headers.slice(2*headers.length/3, headers.length).map(header => (
              <InputBarContainer>
                <EditBar getInput={this.getInput} existingValue={dataset[header]} headers={headers} id={header}/>
              </InputBarContainer>
            ))}
          </InputColumnContainer>
        </FlexBox>
        {submitted ? <LoaderContainer><Loader width="30px" /><br /></LoaderContainer> : null}
        <SubmitButtonContainer>
          <SubmitButton value="Edit Item" onClick={this.makeEditRequest}/>
          <SubmitButton value="Add Item" onClick={this.makeAddRequest}/>
        </SubmitButtonContainer>
      </InputFormContainer>
    );
  }
}

export default EditForm;
