import React from 'react';
import styled from 'styled-components';
import SmoothCollapse from 'react-smooth-collapse';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Person from '../icon/Person';

const MenuContainer = styled.div`
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
  position: fixed;
  z-index: 98;
  color: #fff;
  text-align: right;
`;

const MenuOpenButton = styled.div`
  position: absolute;
  right: 0;
  height: 30px;
  width: 30px;
  background-color: #333333;
  border-radius: 12px;
  cursor: pointer;
`;

const MenuItemContainer = styled.div`
  margin-top: 40px;
  padding: 5px;
  background-color: #333333;
  border-radius: 20px;
`;

const MenuItem = styled.div`
  font-size: 0.8em;
  padding: 10px;
  color: #fff;
  cursor: pointer;
`;

const MenuIconContainer = styled.div`
  margin-top: 25%;
  margin-left: 25%;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
`;

const MenuIconLine = styled.div`
  flex: 1;
  margin: 1.5px;
  background-color: #fff;
  border-radius: 5px;
`;

const UserDetailsContainer = styled.div`
  text-align: center;
  font-size: 0.8em;
  padding: 10px; 5px;
  border-bottom: 1px solid #888888;
`;

const MenuIcon = () => (
  <MenuIconContainer>
    <MenuIconLine />
    <MenuIconLine />
    <MenuIconLine />
  </MenuIconContainer>
)

class Menu extends React.Component{
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      open: true
    }
  }

  onClick = () => {
    this.setState(prevState => ({
      open: !prevState.open 
    }));
  }

  render() {
    const { open } = this.state;
    const { username, history } = this.props;
    return (
      <MenuContainer>
        <MenuOpenButton onClick={this.onClick}><MenuIcon /></MenuOpenButton>
        <SmoothCollapse expanded={open}>
          <MenuItemContainer>
            <UserDetailsContainer>
              <Person username={username} /><br/>
              {username}
            </UserDetailsContainer>
            <MenuItem onClick={() => history.push('./')}>Search</MenuItem>
            <MenuItem onClick={() => history.push('./scan')}>Recent Items</MenuItem>
            <MenuItem onClick={() => history.push('./add')}>New Entry</MenuItem>
            <MenuItem onClick={() => {
              Auth.signOut();
              history.push('./')
              return null;
            }}>Sign Out</MenuItem>
          </MenuItemContainer>
        </SmoothCollapse>
      </MenuContainer>
    )
  }
}

export default withRouter(Menu);
