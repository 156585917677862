import React from 'react';
import styled from 'styled-components';

import SearchBar from './SearchBar'

const SearchButton = styled.input`
  font-size: 0.9em;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #cccccc;
  border-radius: 15px;
  margin-top: 10px;
  left: 0;
`;

const SearchFormContainer = styled.form`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
`;

class SearchForm extends React.Component {
  constructor() {
    super();
    this.state = {
      searchTerm: null,
    }
    this.getInput = this.getInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getInput(input) {
    this.setState({
      searchTerm: input,
    })
  }

  onSubmit = (event) => {
    const { searchTerm } = this.state;
    event.preventDefault();
    window.location = `./search?q=${searchTerm}`;
  }

  render() {
    return (
      <SearchFormContainer onSubmit={this.onSubmit}>
        <SearchBar getInput={this.getInput} />
        <SearchButton type="submit" value="Search" />
      </SearchFormContainer>
    );
  }
}

export default SearchForm;
