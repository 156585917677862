import React from 'react';
import styled from 'styled-components';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import aws4 from 'aws4';
import ReactModal from 'react-modal';

import EditForm from '../edit/EditForm';

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LandingPageStartUpWindow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  left: 0; right: 0;
  background-color: #ffffff;
  z-index: 2;
  padding-top: 40px;
`;

class EditItemPage extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      headers: this.props.headers,
      id: this.props.id,
      data: this.props.data,
      on: false,
    }
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ on: true });
  }

  close() {
    this.setState({ on: false });
  }

  makeRequest = async () => {
    const opts = {
      method: 'GET',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: 'beta/data/autocomplete',
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: 'https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete'
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    const response = await axios.get('https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete', {
        headers: request.headers
      });
  }

  render() {
    const { headers, on, row, data } = this.state;

    if (headers) {
      return (
        <ReactModal
          isOpen={on}
          style={{
            overlay: {
              backgroundColor: '#000000cc',
            },
            content: {
              backgroundColor: 'transparent',
              width: '80vw',
              height: '80vh',
              padding: 0,
              margin: 'auto',
              right: 0,
              left: 0
            },
          }}
          onRequestClose={this.close}
          shouldCloseOnOverlayClick={true} 
        >
          <ModalContainer>
            <LandingPageStartUpWindow>
              <EditForm headers={headers} data={data}/>
            </LandingPageStartUpWindow>
          </ModalContainer>
        </ReactModal>
      );
    }

    return <></>;
  }
}

export default EditItemPage;