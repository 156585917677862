import React from 'react';
import styled from 'styled-components';

import LogoImage from '../../../assets/img/logo.png';

const LogoImg = styled.img`
  width: ${props => props.width};
  cursor: pointer;
`;

const Logo = ({ width }) => (
  <LogoImg src={LogoImage} width={width} onClick={() => window.location = './'}/>
)

export default Logo;
