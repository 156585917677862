import React from 'react';
import styled from 'styled-components';

import LoadingGif from '../../assets/img/loader.gif';

const LoadingContainer = styled.img`
  margin: auto;
  height: 50px;
  top: 0; left: 0; right: 0; bottom: 0;
`;

const LoadingPage = () => (
  <LoadingContainer src={LoadingGif} />
)

export default LoadingPage;
