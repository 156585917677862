import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';

const render = (RootComponent) => {
  ReactDOM.render(
    React.createElement(RootComponent),
    document.getElementById('root'),
  );
};

render(Root);
