import React from 'react';
import styled from 'styled-components';

const TextContainer = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  display: inline-block;
`;

const Text = ({ size, color, weight, content }) => (
  <TextContainer
    size={size}
    color={color}
    weight={weight}
  >
    {content}
  </TextContainer>
);

export default Text;
