import React from 'react';
import styled from 'styled-components';
import Amplify, { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

import BackgroundImage from '../../assets/img/background.jpeg';
import LoadingPage from './LoadingPage';
import LoadingGif from '../../assets/img/loader.gif';
import Logo from '../common/icon/Logo';

Amplify.configure({
    Auth: {
        identityPoolId: 'ap-northeast-2:383d0b56-82a0-4d81-8c57-1f274c8180ad',
        identityPoolRegion: 'ap-northeast-2',
        region: 'ap-northeast-2',
        userPoolId: 'ap-northeast-2_43KnIsFmB',
        userPoolWebClientId: '25c2e37mrqhv1rb9i94stm6vgc',
    }
});

const LoginPageContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(${BackgroundImage});
`;

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  opacity: 0.5;
`;

const LoginPageStartUpWindow = styled.div`
  width: 300px;
  top: 0; bottom: 0; left: 0; right: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 99;
  border-radius: 20px;
`;

const LogoContainer = styled.div`
  text-align:center;
  margin: 20px;
  margin-bottom: 10px;
`;

const LoginForm = styled.form`
  text-align: center;
  padding: 20px;
`;

const InputContainer = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 2em;
  font-size: 0.8em;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px 10px;
  margin-bottom: 10px;
`;

const LoginButtonContainer = styled.div`
  text-align: left;
  margin-top: 5px;
`;

const LoginButton = styled.input`
  background-color: #cccccc;
  cursor: pointer;
  padding: 7px 20px;
  border: none;
  border-radius: 5px;
`;

const LoginLoading = styled.img`
  text-align: left;
  margin-top: 20px;
  height: 30px;
`;

class LoginPage extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loginCheck: true,
      userInfo: null,
      submitted: false,
      loaded: false,
    }
  }

  componentDidMount = () => {
    Auth.currentUserInfo().then(res => {
      this.setState({
        userInfo: res,
        loginCheck: false,
      })});
  }

  submit = async (event) => {
    const { history } = this.props;
    event.preventDefault();
    this.setState({submitted: true});
    let username = document.getElementById('username').value;
    let password = document.getElementById('password').value;

    try {
      let cognitoUser = await Auth.signIn(username, password);
      this.setState({loaded: true});
      history.push('./home')
    } catch {
      alert('Could not verify user. Please check your credentials.');
      this.setState({submitted: false});
    }
  }

  render() {
    const { submitted, loaded, loginCheck, userInfo } = this.state;
    const { history } = this.props; 
    if (loginCheck) {
      return <LoadingPage />;
    } else if (userInfo != null) {
      history.push('./home')
    }
    return (
      <LoginPageContainer>
        <BackgroundOverlay />
        <LoginPageStartUpWindow>
          <LogoContainer><Logo width="200px" /></LogoContainer>
          <LoginForm onSubmit={this.submit}>
            <InputContainer id="username" placeholder="Username" />
            <InputContainer id="password" type="password" placeholder="Password" />
            <LoginButtonContainer>
              <LoginButton type="submit" value="Login" /><br/>
              {submitted && !loaded ? <LoginLoading src={LoadingGif} /> : null}
            </LoginButtonContainer>
          </LoginForm>
        </LoginPageStartUpWindow>
      </LoginPageContainer>
    );
  }
}

export default withRouter(LoginPage);
