import React from 'react';
import styled from 'styled-components';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import aws4 from 'aws4';

import CommonPage from './CommonPage';
import Text from '../common/text/Text';
import InputForm from '../add/InputForm';
import Loader from '../common/icon/Loader';

const LandingPageStartUpWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;
  left: 0; right: 0;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 20px;
`;

class AddItemPageContent extends React.Component {
  constructor() {
    super();
    this.getHeaders();
    this.state = {
      headers: null,
    }
  }

  getHeaders = async () => {
    const opts = {
      method: 'GET',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: 'beta/data/all?q=headers',
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: 'https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all?q=headers'
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    const response = await axios.get('https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/all?q=headers', {
        headers: request.headers
      });

    this.setState({
      headers: response.data,
    });
  }

  makeRequest = async () => {
    const opts = {
      method: 'GET',
      service: 'execute-api',
      region: 'ap-northeast-2',
      path: 'beta/data/autocomplete',
      host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
      url: 'https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete'
    };

    const currentSession = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
    const request = aws4.sign(opts, {
      accessKeyId,
      secretAccessKey,
      sessionToken
    });

    const response = await axios.get('https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete', {
        headers: request.headers
      });
  }

  render() {
    const { headers } = this.state;
    if (headers) {
      return (
        <LandingPageStartUpWindow>
          <InputForm headers={headers} />
        </LandingPageStartUpWindow>
      );
    }
    return <Loader width="50px" />
  }
}

const AddItemPage = () => (
  <CommonPage
    maxWidth="1100px"
    title="PIMS Add Item: 한국디지브이(주)"
    highlight={<Text size="0.85em" content="Add new item to the PIMS database." />}
    subhighlight={<Text size="0.85em" content="Add and delete access are exclusive to administrators." />}
    pageContent={<AddItemPageContent />} 
  />
);

export default AddItemPage;
