import Autosuggest from 'react-autosuggest';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import Amplify, { Auth } from 'aws-amplify';
import aws4 from 'aws4';

const Suggestion = ({ valueKey, value, onClick }) => (
  <span onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
    <SuggestionWrapper>
      {`${valueKey}: ${value}`}
    </SuggestionWrapper>
  </span>
);

Suggestion.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SuggestionWrapper = styled.div`
  padding: 1em;
`;

const AutosuggestWrapper = styled.div`
  .${'react-autosuggest__input'} {
    padding: 1em;
    width: calc(70vw - 2em);
    max-width: calc(600px - 2em);
    border: 1px #00000030 solid;
    border-radius: 20px;
    font-size: 0.95em;
  }

  .${'react-autosuggest__container'} {
    width: 70vw;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .${'react-autosuggest__input--focused'} {
    outline: none;
  }

  .${'react-autosuggest__input--open'} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .${'react-autosuggest__suggestions-container'} {
    display: none;
  }

  .${'react-autosuggest__suggestions-container--open'} {
    display: block;
    position: absolute;
    margin-top: -1px;
    width: 70vw;
    max-width: 600px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Tahoma';
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 2;
  }

  .${'react-autosuggest__suggestions-list'} {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .${'react-autosuggest__suggestion'} {
    cursor: pointer;
    border-bottom: 1px solid #aaa;
  }

  .${'react-autosuggest__suggestion:nth-last-child(1)'} {
    border-bottom: none;
  }

  .${'react-autosuggest__suggestion--highlighted'} {
    background-color: #ddd;
  }
`;

class SearchBar extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  onChange = (event, { newValue }) => {
    const { getInput } = this.props;
    getInput(newValue);
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    if (this.mounted) {
      const regexValue = this.stripSymbols(value).toUpperCase();

      const opts = {
        method: 'GET',
        service: 'execute-api',
        region: 'ap-northeast-2',
        path: `beta/data/autocomplete?q=${regexValue}`,
        host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
        url: `https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete?q=${regexValue}`
      };
      
      const currentSession = await Auth.currentCredentials();
      const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
      const request = aws4.sign(opts, {
        accessKeyId,
        secretAccessKey,
        sessionToken
      });

      axios.get(`https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete?q=${regexValue}`, {
          headers: request.headers
        })
        .then(res => {
          if (this.state.value === value) {
            this.setState({
              suggestions: res.data,
            });
          }
        });
      }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  stripSymbols(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  renderSuggestion(suggestion) {
    return (
      <Suggestion
        valueKey={suggestion.key}
        value={suggestion.value}
        onClick={() => window.location = `./search?q=${suggestion.value}`}
      />
    );
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Begin your search here',
      value,
      onChange: this.onChange,
    };

    return (
      <>
        <AutosuggestWrapper>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
        </AutosuggestWrapper>
      </>
    );
  }
}

export default withRouter(SearchBar);
