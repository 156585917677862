import Autosuggest from 'react-autosuggest';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';

import Amplify, { Auth } from 'aws-amplify';
import aws4 from 'aws4';

const Suggestion = ({ value }) => (
  <span role="button" tabIndex={0}>
    <SuggestionWrapper>
      {`${value}`}
    </SuggestionWrapper>
  </span>
);

Suggestion.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SuggestionWrapper = styled.div`
  padding: 0.5em;
`;

const AutosuggestWrapper = styled.div`
  .${'react-autosuggest__input'} {
    padding: 0.5em;
    width: 20vw;
    border: 1px #00000030 solid;
    border-radius: 5px;
    font-size: 1em;
  }

  .${'react-autosuggest__container'} {
    width: 20vw;
    margin-left: auto;
    margin-right: auto;
  }

  .${'react-autosuggest__input--focused'} {
    outline: none;
  }

  .${'react-autosuggest__input--open'} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .${'react-autosuggest__suggestions-container'} {
    display: none;
  }

  .${'react-autosuggest__suggestions-container--open'} {
    display: block;
    position: absolute;
    width: calc(20vw + 1em + 1px);
    margin-top: -1px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: 'Tahoma';
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 2;
    font-size: 0.9em;
  }

  .${'react-autosuggest__suggestions-list'} {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .${'react-autosuggest__suggestion'} {
    cursor: pointer;
    border-bottom: 1px solid #aaa;
  }

  .${'react-autosuggest__suggestion:nth-last-child(1)'} {
    border-bottom: none;
  }

  .${'react-autosuggest__suggestion--highlighted'} {
    background-color: #ddd;
  }
`;

class EditBar extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  onChange = (event, { newValue }) => {
    const { id, getInput } = this.props;
    getInput(newValue, id);
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const { headers, id } = this.props;

    let noDropDown = [
      headers[1],
      headers[2],
      headers[6],
      headers[7],
      headers[12],
      headers[13],
      headers[18],
      headers[23],
      headers[24],
      headers[25],
      headers[26],
      headers[27],
      headers[28],
      headers[29],
      headers[30],
    ];

    if (noDropDown.includes(id)) {
      return;
    }

    if (this.mounted) {
      const regexValue = this.stripSymbols(value).toUpperCase();

      const opts = {
        method: 'GET',
        service: 'execute-api',
        region: 'ap-northeast-2',
        path: `beta/data/autocomplete?q=${regexValue}&id=${id}`,
        host: 'tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com',
        url: `https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete?q=${regexValue}&id=${id}`
      };

      const currentSession = await Auth.currentCredentials();
      const { accessKeyId, secretAccessKey, sessionToken } = currentSession;
      const request = aws4.sign(opts, {
        accessKeyId,
        secretAccessKey,
        sessionToken
      });

      axios.get(`https://tlhuhxddf4.execute-api.ap-northeast-2.amazonaws.com/beta/data/autocomplete?q=${regexValue}&id=${id}`, {
          headers: request.headers
        })
        .then(res => {
          if (this.state.value === value) {
            this.setState({
              suggestions: res.data,
            });
          }
        });
      }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  stripSymbols(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  renderSuggestion(suggestion) {
    return (
      <Suggestion
        value={suggestion.value}
      />
    );
  }

  render() {
    const { value, suggestions } = this.state;
    const { existingValue, id } = this.props;

    const inputProps = {
      placeholder: id,
      value: existingValue ? existingValue.toString() : value,
      onChange: this.onChange,
    };

    return (
      <>
        <AutosuggestWrapper>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={suggestion => suggestion.value}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
          />
        </AutosuggestWrapper>
      </>
    );
  }
}

export default EditBar;
