import React from 'react';
import SearchForm from '../search/SearchForm';
import CommonPage from './CommonPage';
import Text from '../common/text/Text';

const LandingPage = () => (
  <CommonPage
    maxWidth="700px"
    title="PIMS Database Search: 한국디지브이(주)"
    highlight={<Text size="0.85em" content="Look for all items in the PIMS database that matches the searched term." />}
    subhighlight={<Text size="0.85em" content="Add and delete access are exclusive to administrators." />}
    pageContent={<SearchForm />} 
  />
)

export default LandingPage;
