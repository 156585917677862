import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router';
import LoginPage from '../components/pages/LoginPage';
import LandingPage from '../components/pages/LandingPage';
import SearchResultsPage from '../components/pages/SearchResultsPage';
import FullScanPage from '../components/pages/FullScanPage';
import AddItemPage from '../components/pages/AddItemPage';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Tahoma;
  background-color: #00000020;
`;

class App extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <AppContainer>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/home" component={LandingPage} />
          <Route exact path="/search" component={SearchResultsPage} />
          <Route exact path="/scan" component={FullScanPage} />
          <Route exact path="/add" component={AddItemPage} />
        </Switch>
      </AppContainer>
    );
  }
}

export default App;
