import React from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

import Menu from '../common/menu/Menu';
import LoadingPage from './LoadingPage';
import PageLayout from '../common/layout/PageLayout';

import Logo from '../../assets/img/logosmall.png';

class CommonPage extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      username: null,
      loginCheck: true
    }
  }

  componentDidMount = () => {
    Auth.currentUserInfo().then(res => {
      this.setState({
        username: res ? res.username : null,
        loginCheck: false,
      })});
  }

  render() {
    const { username, loginCheck } = this.state; 
    const { maxWidth, history, title, highlight, subhighlight, pageContent } = this.props;

    if (loginCheck) {
      return <LoadingPage />;
    } else if (username == null) {
      history.push('./')
      return null;
    }
    
    return (
      <>
        <Menu username={username} />
        <PageLayout
          maxWidth={maxWidth}
          img={Logo}
          title={title}
          highlight={highlight}
          subhighlight={subhighlight}
          details={pageContent}
        />
      </>
    );
  }
}

export default withRouter(CommonPage);
