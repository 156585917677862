import styled from 'styled-components';

const FeaturedImage = styled.img`
  width: ${props => props.width};
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #888888;
`;

export default FeaturedImage;