import React from 'react';
import styled from 'styled-components';
import Text from '../text/Text';
import FeaturedImage from '../icon/FeaturedImage';

const ParentPageContainer = styled.div`
  min-width: 100vw;
  min-height: 100vh;
`;

const PageLayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90vw;
  max-width: ${props => props.maxWidth};
  line-height: 1.5em; 
  overflow: hidden;
`;

const PageLayoutMainContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const PageLayoutImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PageLayoutHeadingContainer = styled.div`
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #888888;
`;

const PageLayoutSubHighlightContainer = styled.div`
  margin-top: 7.5px;
  line-height: 1.2em;
`;

const PageLayoutMainTitleContainer = styled.div`
  margin-bottom: 10px;
`;

const PageLayoutDetailsContainer = styled.div`
  width: 100%;
  text-align: justify; 
`;

const PageLayoutDetailsTextContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff60;
  border-radius: 20px;
`;

class PageLayout extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    const { img, title, highlight, subhighlight, details, maxWidth } = this.props;
    return (
      <ParentPageContainer>
        <PageLayoutContainer maxWidth={maxWidth}>
          <PageLayoutMainContainer>
            <PageLayoutImageContainer>
              <FeaturedImage width="70px" src={img} />
            </PageLayoutImageContainer> 
            <PageLayoutHeadingContainer>
              <PageLayoutMainTitleContainer>
                <Text size="1.4em" content={title} />
              </PageLayoutMainTitleContainer>
              {highlight}
              <PageLayoutSubHighlightContainer>
                {subhighlight}
              </PageLayoutSubHighlightContainer>
            </PageLayoutHeadingContainer>
          </PageLayoutMainContainer>    
          <PageLayoutDetailsContainer>
            <PageLayoutDetailsTextContainer>
              {details}
            </PageLayoutDetailsTextContainer>
          </PageLayoutDetailsContainer>
        </PageLayoutContainer>
      </ParentPageContainer>
    );
  }
}

export default PageLayout;
