import React from 'react';
import styled from 'styled-components';

const EmptyResultsPageStartUpWindow = styled.div`
  width: 50vw;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  z-index: 99;
  box-shadow: 0px 0px 2px 2px #00000040;
`;

class LandingPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <EmptyResultsPageStartUpWindow>
        <center> The search returned no results. </center>
        <br /> 
        This could be because...
        <br />
        <ul>
          <li> The database does not have any data on your search. </li>
          <li> 
            Your query is formatted incorrectly. Ensure that the query is formatted 
            as Field1 > Item1, Field2 > Item2 (e.g. DGV R/N > DGV190341, DGV Rev. > PO)
          </li>
        </ul>
      </EmptyResultsPageStartUpWindow>
    );
  }
}

export default LandingPage;
