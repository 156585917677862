import React from 'react';
import styled from 'styled-components';

import Tc from '../../../assets/img/tc.jpg';
import Yc from '../../../assets/img/yc.jpg';
import Hc from '../../../assets/img/hc.jpg';
import Anon from '../../../assets/img/anon.png';

const PersonImg = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid white;
  padding: 2px;
  margin-bottom: 5px;
`;

class Person extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      showUserWindow: false,
    }

    const { username } = props;
    let usernameToImage = {};
    usernameToImage['taecho'] = Tc;
    usernameToImage['youngcho'] = Yc;
    usernameToImage['hyechoi'] = Hc;
    this.imgsrc = username in usernameToImage ? usernameToImage[username] : Tc;
  }

  onClick = () => {
    this.setState(prevState => ({
      showUserWindow: !prevState.showUserWindow,
    }));
  }

  render() {
    const { width } = this.props;
    return (
      <PersonImg src={this.imgsrc} width={width} onClick={this.onClick} />
    );
  }
}

export default Person;
